/*
 * Copyright 2007-2017 Charles du Jeu - Abstrium SAS <team (at) pyd.io>
 * This file is part of Pydio.
 *
 * Pydio is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Pydio is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with Pydio.  If not, see <http://www.gnu.org/licenses/>.
 *
 * The latest code can be found at <https://pydio.com>.
 */

// Backward Compat Table
'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj['default'] = obj; return newObj; } }

var _httpPydioUsersApi = require('./http/PydioUsersApi');

var UsersApi = _interopRequireWildcard(_httpPydioUsersApi);

var Connexion = require('./http/Connexion'),
    MetaCacheService = require('./http/MetaCacheService'),
    PydioApi = require('./http/PydioApi'),
    ResourcesManager = require('./http/ResourcesManager'),
    Logger = require('./lang/Logger'),
    Observable = require('./lang/Observable'),
    Action = require('./model/Action'),
    AjxpNode = require('./model/AjxpNode'),
    Controller = require('./model/Controller'),
    EmptyNodeProvider = require('./model/EmptyNodeProvider'),
    PydioDataModel = require('./model/PydioDataModel'),
    Registry = require('./model/Registry'),
    RemoteNodeProvider = require('./model/RemoteNodeProvider'),
    Repository = require('./model/Repository'),
    User = require('./model/User'),
    ContextMenu = require('./model/ContextMenu'),
    CookiesManager = require('./util/CookiesManager'),
    DOMUtils = require('./util/DOMUtils'),
    FuncUtils = require('./util/FuncUtils'),
    HasherUtils = require('./util/HasherUtils'),
    LangUtils = require('./util/LangUtils'),
    PassUtils = require('./util/PassUtils'),
    PathUtils = require('./util/PathUtils'),
    PeriodicalExecuter = require('./util/PeriodicalExecuter'),
    ActivityMonitor = require('./util/ActivityMonitor'),
    XMLUtils = require('./util/XMLUtils'),
    Pydio = require('./Pydio');

var PydioUsers = {
    Client: UsersApi.UsersApi,
    User: UsersApi.User
};

var namespace = {
    Connexion: Connexion,
    MetaCacheService: MetaCacheService,
    PydioApi: PydioApi,
    PydioUsers: PydioUsers,
    ResourcesManager: ResourcesManager,
    Logger: Logger,
    Observable: Observable,
    Action: Action,
    AjxpNode: AjxpNode,
    Controller: Controller,
    EmptyNodeProvider: EmptyNodeProvider,
    PydioDataModel: PydioDataModel,
    Registry: Registry,
    RemoteNodeProvider: RemoteNodeProvider,
    Repository: Repository,
    User: User,
    ContextMenu: ContextMenu,
    CookiesManager: CookiesManager,
    DOMUtils: DOMUtils,
    FuncUtils: FuncUtils,
    HasherUtils: HasherUtils,
    LangUtils: LangUtils,
    PassUtils: PassUtils,
    PathUtils: PathUtils,
    PeriodicalExecuter: PeriodicalExecuter,
    ActivityMonitor: ActivityMonitor,
    XMLUtils: XMLUtils,
    Pydio: Pydio
};

Object.assign(window, _extends({}, namespace, { PydioCore: namespace }));
