/*
 * Copyright 2007-2017 Charles du Jeu - Abstrium SAS <team (at) pyd.io>
 * This file is part of Pydio.
 *
 * Pydio is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Pydio is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with Pydio.  If not, see <http://www.gnu.org/licenses/>.
 *
 * The latest code can be found at <https://pydio.com>.
 */
'use strict';

exports.__esModule = true;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

var _PydioApi = require('./PydioApi');

var _PydioApi2 = _interopRequireDefault(_PydioApi);

var _utilXMLUtils = require('../util/XMLUtils');

var _utilXMLUtils2 = _interopRequireDefault(_utilXMLUtils);

var User = (function () {
    function User(id, label, type, group, avatar, temporary, external, extendedLabel) {
        _classCallCheck(this, User);

        this._id = id;
        this._label = label;
        this._type = type;
        if (this._type === 'group') {
            this._group = id;
        }
        this._avatar = avatar;
        this._temporary = temporary;
        this._external = external;
        this._extendedLabel = extendedLabel;
    }

    User.fromObject = function fromObject(user) {
        return new User(user.id, user.label, user.type, user.group, user.avatar, user.temporary, user.external);
    };

    User.prototype.asObject = function asObject() {
        return {
            id: this._id,
            label: this._label,
            type: this._type,
            group: this._group,
            avatar: this._avatar,
            temporary: this._temporary,
            external: this._external,
            extendedLabel: this._extendedLabel
        };
    };

    User.prototype.getId = function getId() {
        return this._id;
    };

    User.prototype.getLabel = function getLabel() {
        return this._label;
    };

    User.prototype.getType = function getType() {
        return this._type;
    };

    User.prototype.getGroup = function getGroup() {
        return this._group;
    };

    User.prototype.getAvatar = function getAvatar() {
        return this._avatar;
    };

    User.prototype.getTemporary = function getTemporary() {
        return this._temporary;
    };

    User.prototype.getExternal = function getExternal() {
        return this._external;
    };

    User.prototype.getExtendedLabel = function getExtendedLabel() {
        return this._extendedLabel;
    };

    return User;
})();

var UsersApi = (function () {
    function UsersApi() {
        _classCallCheck(this, UsersApi);
    }

    UsersApi.authorizedUsersStartingWith = function authorizedUsersStartingWith(token, callback) {
        var usersOnly = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];
        var existingOnly = arguments.length <= 3 || arguments[3] === undefined ? false : arguments[3];

        var params = {
            get_action: 'user_list_authorized_users',
            value: token,
            format: 'json'
        };
        if (usersOnly) {
            params['users_only'] = 'true';
        }
        if (existingOnly) {
            params['existing_only'] = 'true';
        }
        _PydioApi2['default'].getClient().request(params, function (transport) {
            var suggestions = [];
            if (transport.responseXML) {
                var lis = _utilXMLUtils2['default'].XPathSelectNodes(transport.responseXML, '//li');
                lis.map(function (li) {
                    var spanLabel = _utilXMLUtils2['default'].XPathGetSingleNodeText(li, 'span[@class="user_entry_label"]');
                    suggestions.push(new User(li.getAttribute('data-entry_id'), li.getAttribute('data-label'), li.getAttribute('class'), li.getAttribute('data-group'), li.getAttribute('data-avatar'), li.getAttribute('data-temporary') ? true : false, li.getAttribute('data-external') == 'true', spanLabel));
                });
            } else if (transport.responseJSON) {
                var data = transport.responseJSON;
                data.map(function (entry) {
                    var id = entry.id;
                    var label = entry.label;
                    var type = entry.type;
                    var group = entry.group;
                    var avatar = entry.avatar;
                    var temporary = entry.temporary;
                    var external = entry.external;

                    suggestions.push(new User(id, label, type, group, avatar, temporary, external, label));
                });
            }
            callback(suggestions);
        });
    };

    UsersApi.createUserFromPost = function createUserFromPost(postValues, callback) {
        postValues['get_action'] = 'user_create_user';
        _PydioApi2['default'].getClient().request(postValues, (function (transport) {
            callback(postValues, transport.responseJSON);
        }).bind(this));
    };

    UsersApi.deleteUser = function deleteUser(userId, callback) {
        _PydioApi2['default'].getClient().request({
            get_action: 'user_delete_user',
            user_id: userId
        }, function (transport) {
            callback();
        });
    };

    UsersApi.saveSelectionSupported = function saveSelectionSupported() {
        return global.pydio.getController().actions.get('user_team_create') !== undefined;
    };

    UsersApi.deleteTeam = function deleteTeam(teamId, callback) {
        teamId = teamId.replace('/AJXP_TEAM/', '');
        _PydioApi2['default'].getClient().request({
            get_action: 'user_team_delete',
            team_id: teamId
        }, function (transport) {
            callback(transport.responseJSON);
        });
    };

    UsersApi.saveSelectionAsTeam = function saveSelectionAsTeam(teamName, userIds, callback) {
        _PydioApi2['default'].getClient().request({
            get_action: 'user_team_create',
            team_label: teamName,
            'user_ids[]': userIds
        }, function (transport) {
            callback(transport.responseJSON);
        });
    };

    UsersApi.addUserToTeam = function addUserToTeam(teamId, userId, callback) {
        teamId = teamId.replace('/AJXP_TEAM/', '');
        _PydioApi2['default'].getClient().request({
            get_action: 'user_team_add_user',
            team_id: teamId,
            user_id: userId
        }, function (transport) {
            callback(transport.responseJSON);
        });
    };

    UsersApi.removeUserFromTeam = function removeUserFromTeam(teamId, userId, callback) {
        teamId = teamId.replace('/AJXP_TEAM/', '');
        _PydioApi2['default'].getClient().request({
            get_action: 'user_team_delete_user',
            team_id: teamId,
            user_id: userId
        }, function (transport) {
            callback(transport.responseJSON);
        });
    };

    UsersApi.updateTeamLabel = function updateTeamLabel(teamId, newLabel, callback) {
        teamId = teamId.replace('/AJXP_TEAM/', '');
        _PydioApi2['default'].getClient().request({
            get_action: 'user_team_update_label',
            team_id: teamId,
            team_label: newLabel
        }, function (transport) {
            callback(transport.responseJSON);
        });
    };

    return UsersApi;
})();

exports.User = User;
exports.UsersApi = UsersApi;
