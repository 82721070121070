/*
 * Copyright 2007-2017 Charles du Jeu - Abstrium SAS <team (at) pyd.io>
 * This file is part of Pydio.
 *
 * Pydio is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Pydio is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with Pydio.  If not, see <http://www.gnu.org/licenses/>.
 *
 * The latest code can be found at <https://pydio.com/>.
 *
 */
/**
 * Utilitary class for language specific methods
 */
"use strict";

exports.__esModule = true;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var FuncUtils = (function () {
    function FuncUtils() {
        _classCallCheck(this, FuncUtils);
    }

    FuncUtils.Empty = function Empty() {};

    FuncUtils.Identity = function Identity(object) {
        return object;
    };

    FuncUtils.executeFunctionByName = function executeFunctionByName(functionName, context) {
        var args = Array.prototype.slice.call(arguments, 2);
        var namespaces = functionName.split(".");
        var func = namespaces.pop();
        for (var i = 0; i < namespaces.length; i++) {
            context = context[namespaces[i]];
        }
        return context[func].apply(context, args);
    };

    FuncUtils.getFunctionByName = function getFunctionByName(functionName, context) {
        var args = Array.prototype.slice.call(arguments, 2);
        var namespaces = functionName.split(".");
        var func = namespaces.pop();
        for (var i = 0; i < namespaces.length; i++) {
            if (!context[namespaces[i]]) {
                return null;
            }
            context = context[namespaces[i]];
        }
        return context[func];
    };

    FuncUtils.bufferCallback = function bufferCallback(name, time, callback) {
        if (window[name]) {
            window.clearTimeout(window[name]);
        }
        window[name] = window.setTimeout(callback, time);
    };

    return FuncUtils;
})();

exports["default"] = FuncUtils;
module.exports = exports["default"];
